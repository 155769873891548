@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {


	*,
	:before,
	:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
		border-width: 0;
		border-style: solid;
	}


html, body {
  margin: 0;
  font-family: Helvetica, sans-serif;
}
}