.login-wrapper {
    display: flex;
    margin-top: 6em;
    flex-direction: column;
    align-items: center;
}

  	button,
	input[type='button'],
	input[type='submit'] {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		padding: 12px 24px;
		gap: 12px;
		margin: 8px 0;
		width: 100%;
		height: 48px;
		box-shadow: 0 1px 3px #00000014;
		border-radius: 8px;
		flex: none;
		order: 2;
		align-self: stretch;
		flex-grow: 0;
	}