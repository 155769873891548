

a {
  color: rgb(30 58 138);
}
h1 {
  font-size: 1.5rem;
}
/* header */

.hidden {
  display: none;
}
.header {
  box-shadow: 1px 1px 4px 0 rgba(0,0,0,.1);
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 3;
}

.header ul {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
}

.header li a {
  display: block;
  padding: 20px 20px;
  border-right: 1px solid #f4f4f4;
  text-decoration: none;
}

.header .logo {
  display: block;
  float: left;
  font-weight: 700;
  font-size: 1.2rem;
  padding: 20px 20px;
  text-decoration: none;
}
.logo img {
  height: 50px; 
}

/* menu */

.header .menu {
  clear: both;
  max-height: 0;
  transition: max-height .2s ease-out;
}

/* menu icon */

.header .menu-icon {
  cursor: pointer;
  display: inline-block;
  float: right;
  padding: 34px 20px;
  position: relative;
  user-select: none;
}

.header .menu-icon .navicon {
  background: #333;
  display: block;
  height: 4px;
  position: relative;
  transition: background .2s ease-out;
  width: 32px;
}

.header .menu-icon .navicon:before,
.header .menu-icon .navicon:after {
  background: #333;
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  transition: all .2s ease-out;
  width: 100%;
}

.header .menu-icon .navicon:before {
  top: 10px;
}

.header .menu-icon .navicon:after {
  top: -10px;
}

/* menu btn */

.header .menu-btn {
  display: none;
}

.header .menu-btn:checked ~ .menu {
  max-height: 240px;
}

.header .menu-btn:checked ~ .menu-icon .navicon {
  background: transparent;
}

.header .menu-btn:checked ~ .menu-icon .navicon:before {
  transform: rotate(-45deg);
}

.header .menu-btn:checked ~ .menu-icon .navicon:after {
  transform: rotate(45deg);
}

.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
  top: 0;
}

i {
  font-size: 2.4rem;
}

.footer {
  margin-top: 50px;
  padding-block: 50px;
  color: #f4f4f4;
}

.error {
  margin: 3em 0;
  padding: 2em;
  border-radius: 2em;
}

.footer_container {
  max-width: 1160px;
  margin: auto;
  padding: 0 15px;
}


.footer_container p {
  display: block;
margin-block-start: 1em;
margin-block-end: 1em;
margin-inline-start: 0px;
margin-inline-end: 0px;
font-size: .9rem;
line-height: 2rem;
}
.footer_top {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  row-gap: 20px;
}

.footer_title {
  font-size: 18px;
  color: #fff;
  font-weight: 500;
  margin-bottom: 20px;
}

.footer_list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.footer_list-item {
  list-style: none;
}

.footer_list-link {
  text-decoration: none;
  font-size: .9rem;
  transition: color .25s;
}

.footer_list-link:hover {
  color: #fff;
}

.footer_divider {
  margin-block: 25px;
  border: none;
  border-top: 1px solid rgba(0,0,0,.1);
}

.footer_bottom {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
}

.footer_bottom .footer_list {
  flex-direction: row;
}

.main {
  padding: 7em 2em;
}
.home {
  position: fixed;
  bottom: 0;
  left: 0;
  margin: auto;
  max-height: 150px;
  max-width: 150px;
  right: 0;
  top: 0;
}

.voucher {
  padding: 1em;
  background-color: #f4f4f4;
  border-radius: 1em;
  margin-bottom: 1em;
  margin-top: 1em;
}

	.loader-center {
		width: 100%;
	}

	.loader {
		border: 10px solid #fff;
		border-radius: 50%;
		border-top: 10px solid #3498db;
		width: 40px;
		height: 40px;
		margin: auto;
		-webkit-animation: spin 2s linear infinite; /* Safari */
		animation: spin 2s linear infinite;
	}

  	/* Safari */
	@-webkit-keyframes spin {
		0% {
			-webkit-transform: rotate(0deg);
		}
		100% {
			-webkit-transform: rotate(360deg);
		}
	}

	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}

  .blok {
    max-width: 1160px;
    margin: 20px auto;
    background-color: #fff;
    padding: 20px;
    border-radius: 20px;
  }

  	.label-form {
		width: 100%;
	}

	.label-form label {
		font-size: 14px;
	}

  select,
  	input[type='text'],
	input[type='number'],
  	input[type='tel'],
    	input[type='date'],
	input[type='datetime-local'],
	input[type='password'] {
		box-sizing: border-box;
		display: flex;
		flex-direction: row;
		align-items: center;
		padding: 12px 12px 12px 16px;
		font-size: 14px;
		margin: 8px 0;
		width: 100%;
    background-color: #fff;
    -webkit-min-logical-width: calc(100% - 16px);
		height: 40px;
		border: 1px solid;
		border-radius: 8px;
		flex: none;
		order: 1;
		align-self: stretch;
		flex-grow: 0;
		outline: none;
	}

	.select {
		box-sizing: border-box;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding: 12px 12px 12px 16px;
		font-size: 14px;
		margin: 8px 0;
		width: 100%;
		height: 40px;
		border-radius: 8px;
		flex: none;
		order: 0;
		align-self: stretch;
		flex-grow: 0;
	}
  small {
    margin-left: 15px;
  }

  	button,
	input[type='button'],
	input[type='submit'],
	input[type='reset'] {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		padding: 12px 24px;
		gap: 12px;
		margin: 8px 0;
		width: 100%;
		height: 48px;
		box-shadow: 0 1px 3px #00000014;
		border-radius: 8px;
		flex: none;
		order: 2;
		align-self: stretch;
		flex-grow: 0;
	}
  .error {
  margin: 0 30px;
}




/* 48em = 768px */

@media (min-width: 48em) {
  .header li {
    float: left;
  }
  .header .logo {
  font-size: 2rem;
    padding: 8px 20px;
}
  .header li a {
    padding: 20px 30px;
  }
  .header .menu {
    clear: none;
    float: right;
    max-height: none;
  }
  .header .menu-icon {
    display: none;
  }

}